<template>
  <li class="container row">
    <router-link class="cover" :to="path" target="_blank">
      <img :src="coverImage" :onerror="defaultImg" />
    </router-link>
    <div class="info flex1">
      <div class="title size22 bold">
        <router-link :to="path" target="_blank" class="black">{{
          houseItem.name
        }}</router-link>
      </div>
      <div class="row sb_center">
        <div>
          <div class="house-info mt20 row s_center size14">
            <i class="el-icon-s-home whiteGray size16 pr10"></i>
            <span class="">{{ houseItem.rentCount }}套正在出租</span>
          </div>
          <div class="house-info mt20 row s_center size14">
            <i class="el-icon-star-on whiteGray size18 pr10"></i>
            <span v-if="houseItem.address"
              >{{ houseItem.address }}
              {{ houseItem.completeYear ? "/" : "" }}</span
            >
            <span v-if="houseItem.completeYear" class="pl5">
              {{ houseItem.completeYear }}年建成</span
            >
          </div>
        </div>

        <div class="price-wrap row s_center">
          <div style="margin-right: 30px">
            <div class="size14 bold">
              <span
                class="size26"
                :class="[houseItem.price ? 'red' : 'whiteGray']"
                >{{ houseItem.price || "暂无数据" }}</span
              >
              <span v-if="houseItem.price">元/m²</span>
            </div>
            <div class="mt20 size12 whiteGray">
              {{ curMonth }}二手房参考均价
            </div>
          </div>
          <div>
            <div class="size14 bold black">
              <span class="size26">{{ houseItem.saleCount }}</span>
              <span v-if="houseItem.saleCount">套</span>
            </div>
            <div class="mt20 size12 whiteGray">在售二手房</div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      defaultImg: `this.src="${Constant.DEFAULT_HOUSE}"`,
    };
  },
  computed: {
    path() {
      let result = {
        name: "house-estate-detail",
        query: {
          guid: this.houseItem.guid,
        },
      };
      return result;
    },
    coverImage() {
      return this.houseItem.coverImage || Constant.DEFAULT_HOUSE;
    },
    curMonth() {
      const date = new Date();
      return date.getMonth() + 1 + "月";
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 35px 0;
  border-bottom: 1px solid #f1f1f1;
  .cover {
    width: 232px;
    height: 174px;
    img {
      width: 100%;
      height: 100%;
      border: 1px solid #eeeeee;
      border-radius: 2px;
    }
  }
  .info {
    margin-left: 36px;
    .title {
      &:hover a {
        color: #00ae66;
      }
    }
  }
  .house-info {
    color: #616669;
  }
}
</style>

