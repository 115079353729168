<template>
    <div v-if="showSaveOk" class="container modal" @click="$emit('update-save-ok',false)">
        <div class="modal-inner flex sb_center">
            <div class="left"></div>
            <div class="right">
                <span class="size24 bold green">已成功保存搜索条件！</span>
                <p>您可在搜索框右侧下拉列表中快速使用该条件。该条件有新房源出现时，我们将会用站内提醒的方式来通知您</p>
                <a href="javascript:;" class="close">确定</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    showSaveOk: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .modal-inner {
    position: fixed;
    background: url("../../../assets/icon/app_success_bg.jpg") no-repeat;
    width: 427px;
    height: 133px;
    z-index: 10000;
    padding: 50px;
    .left {
      background: url("../../../assets/icon/app_save-bg.png") no-repeat 0 0;
      width: 70px;
      height: 70px;
    }
    .right {
      width: 325px;
      font-size: 12px;
      color: #9399a5;
      line-height: 23px;
      text-align: left;
    }
    .close {
      width: 114px;
      height: 43px;
      display: inline-block;
      margin-right: 15px;
      text-align: center;
      line-height: 43px;
      color: #fff;
      background: #32ad68;
      margin-top: 25px;
      font-size: 14px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

