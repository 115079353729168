<template>
    <li class="container size13" style="margin-bottom:24px">
        <router-link 
            :to="{
            name: 'new-estate-detail',
            query: {
                guid: houseItem.guid,
            }}" target="_blank">
            <img v-if="houseItem.coverImage" :src="houseItem.coverImage" alt="" />
            <img v-else  :src="coverImage" />
            <p class="title ellipsis black">{{houseItem.title}}</p>
            <p class="desc flex">
                <span class="pr5" v-if="houseItem.room || houseItem.hall ">{{houseItem.room ? houseItem.room + "室" : ""}}{{houseItem.hall ? houseItem.hall + "厅" : ""}}</span>
                <span class="pr5" v-if="houseItem.buildingArea"> | {{houseItem.buildingArea}}平米 </span>
                <span v-if="houseItem.orientation !== '未选择'"> | {{houseItem.orientation}}</span>
            </p>
            <p class="price" v-show="houseItem.rentPrice">{{houseItem.rentPrice}}元/月</p>
        </router-link>
    </li>
</template>

<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      coverImage: Constant.DEFAULT_HOUSE
    };
  }
};
</script>
<style lang="less" scoped>
.container {
  img {
    width: 100%;
    height: 127px;
    margin-bottom: 16px;
    border-radius: 2px;
    object-fit: cover;
  }
  .title,
  .desc {
    margin-bottom: 8px;
  }
  .desc {
    color: #9399a5;
  }
  .price {
    color: #fa5741;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 3px;
  }
}
</style>

